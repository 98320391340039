/* eslint-disable camelcase */
const handylearning = { 
  //網頁
  WebName: 'HandyLearning',
  WebChineseName: '掌中學院',
  WebYear: '2024',
  WebLogo: 'assets/img/handylearn_logo2.png',
  HeaderLogo: 'assets/img/handylearn_logo2.png',
  FooterLogo: 'assets/img/handylearn_logo2_white.png',
  WebPhone: '02-6626-6613',
  //購物車
  ShoppingIcon: 'assets/img/right-cart.png',
  //導引
  GuideLogo: 'assets/img/hlwebfont.png',
  GuideIcon: 'assets/img/right-web 2.png',
  //QA
  QaLogo: 'assets/img/right-QA 2.png',
  QaFont: 'assets/img/hlqafont.png',
  QaBackground: 'assets/img/qafont.png',
  //sell
  SellLogo: 'assets/img/right-line 2.png',
  //點數
  PointName: 'HeyPoint',
  PointSuffix: 'HP',
  PointClass: 'hl-text-info',
  //Header
  HeaderLessonBuyName: '購買課程',
  HeaderVoucherName: '兌換序號',
  HeaderNotifyName: '通知提醒',
  HeaderDefaultName: '功能選單',
  HeaderAccount: '個人設定',
  HeaderAccountClass: 'fa-user-gear',
  HeaderOrder: '訂單查詢',
  HeaderOrderClass: 'fa-square-list',
  HeaderLesson: '進入學習',
  HeaderLessonClass: 'fa-regular fa-book-open-cover',
  HeaderHistory: '學習數據',
  HeaderHistoryClass: ' fa-scroll',
  HeaderMapClass: 'fa-street-view',
  HeaderMessage: '管家老師',
  HeaderMessageClass: 'fa-user-tie-hair',
  HeaderCollect: '追蹤收藏',
  HeaderCollectClass: 'fa-circle-bookmark',
  //主帳號登入
  UserProfileMain: '家長帳號管理',
  MainName: '家長',
  SubMemberManage: '孩子帳號管理',
  SubMemberName: '孩子帳號',
  SubMemberLoginName: '孩子',
  UserPoints: 'HeyPoint管理',
  //子帳號登入
  SubUserProfileMain: '孩子帳號管理',
  //訂單
  OrderClass: 'hl-bg-color-yellow',
  //我的課程
  MyLesson: '學習課表',
  MyLessonIcon: 'fa-book',
  ReviewLesson: '已到期課程',
  ChooseContent: '請選擇孩子名稱或新增',
  //學習紀錄
  History: '學習數據',
  HistoryStyle: 'background-color:#F9C369 !important',
  //學習地圖
  MapAccountName: '家長/孩子',
  //管家留言
  MessageTitle: '管家',
  MessageBottom: '管家服務時間為週一～週五14:00-21:30，服務時間內會盡快回覆同學呦！',
  //我的收藏
  CollectExam: 'e評量收藏',
  //序號
  VouchersBGClass: 'hl-bg-blue',
  //Footer
  AboutUs: '了解我們',
  NewsList: '媒體報導',
  ServeContract: '服務條款',
  Privacy: '隱私權保護聲明',
  Questions: '常見問答',
  ContactUs: '聯絡信箱',
  Facebook: '粉絲專頁',
};



export { handylearning };