/*----------訂閱制頁面----------*/
let Subscription = [
  /*---課程 Start---*/
  {
    path: '/LessonDetailSubscription',
    name: 'LessonDetailSubscription',
    component: () => import('@/views/php/Subscription/LessonDetailSubscription.vue')
  },
  /*---課程 Start---*/
];
    
export default Subscription;