
let SbGen5 = [
  /* // Yves modify SBDW5-41 禁止打開
  {
    path: '/',
    component: () => import('@/views/php/Home.vue')
  },*/
  {
    path: '/Home',
    name: 'Home',
    component: () => import('@/views/php/Home.vue')
  },
  {
    path: '/UnitTest1',
    name: 'UnitTest1',
    component: () => import('@/views/php/UnitTest1.vue')
  },
  {
    path: '/UnitTest2',
    name: 'UnitTest2',
    component: () => import('@/views/php/UnitTest2.vue')
  },
  {
    path: '/UnitTest3',
    name: 'UnitTest3',
    component: () => import('@/views/php/UnitTest3.vue')
  },
  {
    path: '/UnitTest',
    name: 'UnitTest',
    component: () => import('@/views/php/UnitTest.vue')
  },
  {
    path: '/ShoppingCart',
    name: 'ShoppingCart',
    component: () => import('@/views/php/ShoppingCart.vue')
  },
  {
    path: '/ShoppingCartComplete',
    name: 'ShoppingCartComplete',
    component: () => import('@/views/php/ShoppingCartComplete.vue')
  },
  {
    path: '/OrderRecord',
    name: 'OrderRecord',
    component: () => import('@/views/php/OrderRecord.vue')
  },
  {
    path: '/VideoPlayer',
    name: 'VideoPlayer',
    component: () => import('@/views/php/VideoPlayer.vue')
  },
  {
    path: '/ProductList',
    name: 'ProductList',
    component: () => import('@/views/php/ProductList.vue')
  },
  {
    path: '/ProductDetail',
    name: 'ProductDetail',
    component: () => import('@/views/php/ProductDetail.vue')
  },
  {
    path: '/ExtendedReading',
    name: 'ExtendedReading',
    component: () => import('@/views/php/ExtendedReading.vue')
  },
  {
    path: '/MyLearn',
    name: 'MyLearn',
    component: () => import('@/views/php/MyLearn.vue')
  },
  {
    path: '/ChangeEditionSetting',
    name: 'ChangeEditionSetting',
    component: () => import('@/views/php/ChangeEditionSetting.vue')
  },
  {
    path: '/ChangeEditionSettingRenew',
    name: 'ChangeEditionSettingRenew',
    component: () => import('@/views/php/ChangeEditionSettingRenew.vue')
  },
  {
    path: '/EditionSetting',
    name: 'EditionSetting',
    component: () => import('@/views/php/EditionSetting.vue')
  },
  {
    path: '/MessageList',
    name: 'MessageList',
    component: () => import('@/views/php/MessageList.vue')
  },
  {
    path: '/MessageDetail',
    name: 'MessageDetail',
    component: () => import('@/views/php/MessageDetail.vue')
  },
  {
    path: '/LessonDetail',
    name: 'LessonDetail',
    component: () => import('@/views/php/LessonDetail.vue')
  },
  {
    path: '/ProductListEx',
    name: 'ProductListEx',
    component: () => import('@/views/php/ProductListEx.vue')
  },  
  {
    path: '/ExtendedReadingDetail',
    name: 'ExtendedReadingDetail',
    component: () => import('@/views/php/ExtendedReadingDetail.vue')
  },
  {
    path: '/ShopLine',
    name: 'ShopLine',
    component: () => import('@/views/php/ShopLine.vue')
  }, 
  {
    path: '/Questions',
    name: 'Questions',
    component: () => import('@/views/php/Questions.vue')
  }, 
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import('@/views/php/ContactUs.vue')
  },  
  {
    path: '/Terms',
    name: 'Terms',
    component: () => import('@/views/php/Terms.vue')
  }, 
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('@/views/php/Privacy.vue')
  },
  {
    path: '/NewsList',
    name: 'NewsList',
    component: () => import('@/views/php/NewsList.vue')
  },
  {
    path: '/NewsDetail',
    name: 'NewsDetail',
    component: () => import('@/views/php/NewsDetail.vue')
  },
  {
    path: '/UserProfileMain',
    name: 'UserProfileMain',
    component: () => import('@/views/php/UserProfileMain.vue')
  },
  {
    path: '/UserProfileSub',
    name: 'UserProfileSub',
    component: () => import('@/views/php/UserProfileSub.vue')
  },
  { //Lite
    path: '/UserProfileMainLite',
    name: 'UserProfileMainLite',
    component: () => import('@/views/php/UserProfileMainLite.vue')
  },
  { //Lite 班級
    path: '/UserClassLite',
    name: 'UserClassLite',
    component: () => import('@/views/php/UserClassLite.vue')
  },
  {
    path: '/UserPoints',
    name: 'UserPoints',
    component: () => import('@/views/php/UserPoints.vue')
  },
  {
    path: '/UserQuestionPoints',
    name: 'UserQuestionPoints',
    component: () => import('@/views/php/UserQuestionPoints.vue')
  },
  {
    path: '/UserQuestionPointsSub',
    name: 'UserQuestionPointsSub',
    component: () => import('@/views/php/UserQuestionPointsSub.vue')
  },
  {
    path: '/SubMemberManage',
    name: 'SubMemberManage',
    component: () => import('@/views/php/SubMemberManage.vue')
  },
  {//Lite 學生
    path: '/SubMemberManageLite',
    name: 'SubMemberManageLite',
    component: () => import('@/views/php/SubMemberManageLite.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('@/views/php/AboutUs.vue')
  },
  {
    path: '/ServeContract',
    name: 'ServeContract',
    component: () => import('@/views/php/ServeContract.vue')
  },
  {
    // Yves modify SBDW5-2517
    path: '/:catchAll(.*)',
    name: 'Initialize',
    component: () => import('@/views/php/Initialize.vue')
  },
  {
    path: '/PaymentChose',
    name: 'PaymentChose',
    component: () => import('@/views/php/PaymentChose.vue')
  },
  {
    path: '/StudentMessage',
    name: 'StudentMessage',
    component: () => import('@/views/php/StudentMessage.vue')
  },
  {
    // Q.1.1 考卷測驗-自動命題
    path: '/ExamPaperSetting',
    name: 'ExamPaperSetting',
    component: () => import('@/views/php/ExamPaperSetting.vue')
  },
  {
    // Q.1.1 新版考卷測驗-自動命題(新版)
    path: '/ExamPaperSettingNew',
    name: 'ExamPaperSettingNew',
    component: () => import('@/views/php/ExamPaperSettingNew.vue')
  },
  {
    path: '/CheckCashFlow',
    name: 'CheckCashFlow',
    component: () => import('@/views/php/CheckCashFlow.vue')
  },
  {
    path: '/ProductSelectFunction',
    name: 'ProductSelectFunction',
    component: () => import('@/views/php/ProductSelectFunction.vue')
  },
  {
    path: '/ExamRange',
    name: 'ExamRange',
    component: () => import('@/views/php/ExamRange.vue')
  },
  {
    // Q.1.2 考卷測驗-題目
    path: '/StartExam',
    name: 'StartExam',
    component: () => import('@/views/php/StartExam.vue')
  },
  {
    // Q.1.2 考卷測驗-題目(新版)
    path: '/StartExamNew',
    name: 'StartExamNew',
    component: () => import('@/views/php/StartExamNew.vue')
  },
  {
    // Q.1.1 考卷測驗-自動命題-列印
    path: '/PrintExamPaper',
    name: 'PrintExamPaper',
    component: () => import('@/views/php/PrintExamPaper.vue')
  },
  {
    // Q.1.1 考卷測驗-自動命題-列印(新版)
    path: '/PrintExamPaperNew',
    name: 'PrintExamPaperNew',
    component: () => import('@/views/php/PrintExamPaperNew.vue')
  },
  {
    // U.0.0 我的學習-學習紀錄-無有效產品
    path: '/LearnHistory',
    name: 'LearnHistory',
    component: () => import('@/views/php/LearnHistory.vue')
  },
  {
    // U.3.1 我的學習-百萬題庫-成績單
    path: '/LearnHistoryUserExamPaper',
    name: 'LearnHistoryUserExamPaper',
    component: () => import('@/views/php/LearnHistoryUserExamPaper.vue')
  },
  {
    // U.5.3 我的學習-百萬題庫-錯題記錄
    path: '/LearnHistoryUserExam',
    name: 'LearnHistoryUserExam',
    component: () => import('@/views/php/LearnHistoryUserExam.vue')
  },
  {
    // P-1-1 我的收藏
    path: '/MyCollect',
    name: 'MyCollect',
    component: () => import('@/views/php/MyCollect.vue')
  },
  {
    // P-3-2 題目收藏-單題練習
    path: '/SingleExam',
    name: 'SingleExam',
    component: () => import('@/views/php/SingleExam.vue')
  },
  {
    // 題目收藏
    path: '/MixSingleExam',
    name: 'MixSingleExam',
    component: () => import('@/views/php/Curriculum/MixSingleExam.vue')
  },
  {
    // U.4.1 我的學習-百萬題庫-出卷紀錄
    path: '/LearnHistoryExamPaper',
    name: 'LearnHistoryExamPaper',
    component: () => import('@/views/php/LearnHistoryExamPaper.vue')
  },
  {
    // U-3-2 我的學習-成績單內容
    path: '/UserExamPaper',
    name: 'UserExamPaper',
    component: () => import('@/views/php/UserExamPaper.vue')
  },
  {
    // U-3-2 我的學習-成績單內容(新版)
    path: '/UserExamPaperNew',
    name: 'UserExamPaperNew',
    component: () => import('@/views/php/UserExamPaperNew.vue')
  },
  {
    // U-3-6 我的學習-成績單-學習適性診斷
    path: '/UserExamPaperRelationLesson',
    name: 'UserExamPaperRelationLesson',
    component: () => import('@/views/php/UserExamPaperRelationLesson.vue')
  },
  {
    // 播放器 Sample
    path: '/UnitTestVideoPlayer',
    name: 'UnitTestVideoPlayer',
    component: () => import('@/views/php/UnitTestVideoPlayer.vue')
  },
  {
    // Q-1-5 考卷測驗-錯題考試
    path: '/CorrectUserExam',
    name: 'CorrectUserExam',
    component: () => import('@/views/php/CorrectUserExam.vue')
  },
  {
    // U-6-1 我的學習-百萬題庫-雷達圖
    path: '/LearnHistoryExamDashboard',
    name: 'LearnHistoryExamDashboard',
    component: () => import('@/views/php/LearnHistoryExamDashboard.vue')
  },
  { //解惑平台
    path: '/QuestionList',
    name: 'QuestionList',
    component: () => import('@/views/php/QuestionList.vue')
  }, 
  { //解惑平台 Lite版
    path: '/QuestionListLite',
    name: 'QuestionListLite',
    component: () => import('@/views/php/QuestionListLite.vue')
  }, 
  {
    // F-10-3 我的課程-學習計畫-新增學習項目
    path: '/AddLearnPlan',
    name: 'AddLearnPlan',
    component: () => import('@/views/php/AddLearnPlan.vue')
  },
  {
    // F-10-3 我的課程-學習計畫-新增學習項目（只新增一個小節）
    path: '/AddCoursePlan',
    name: 'AddCoursePlan',
    component: () => import('@/views/php/AddCoursePlan.vue')
  },
  {
    path: '/PaymentView',
    name: 'PaymentView',
    component: () => import('@/views/php/PaymentView.vue')
  },
  {
    path: '/Invoice',
    name: 'Invoice',
    component: () => import('@/views/php/Invoice.vue')
  },
  {
    // 第三方登入
    path: '/Openid',
    name: 'Openid',
    component: () => import('@/views/php/Openid.vue')
  },
  {
    // Initialize
    path: '/Initialize',
    name: 'Initialize',
    component: () => import('@/views/php/Initialize.vue')
  },
  {
    // Lite 版登入
    path: '/LiteLogin',
    name: 'LiteLogin',
    component: () => import('@/views/php/LiteLogin.vue')
  },
  {
    // 活動序號
    path: '/Vouchers',
    name: 'Vouchers',
    component: () => import('@/views/php/Vouchers.vue')
  },
  {
    // 活動序號 - 產品設定
    path: '/VoucherProductSetting',
    name: 'VoucherProductSetting',
    component: () => import('@/views/php/VoucherProductSetting.vue')
  },
  {
    // 活動序號 - 選擇產品並設定
    path: '/VoucherSelectProductSetting',
    name: 'VoucherSelectProductSetting',
    component: () => import('@/views/php/VoucherSelectProductSetting.vue')
  },
  {
    // 活動序號 - 直購序號模組
    path: '/VoucherPaySelectProductSetting',
    name: 'VoucherPaySelectProductSetting',
    component: () => import('@/views/php/VoucherPaySelectProductSetting.vue')
  },
  {
    // 學習地圖
    path: '/LearningMap',
    name: 'LearningMap',
    component: () => import('@/views/php/LearningMap.vue')
  },
  {
    // 學習地圖詳細資料
    path: '/LearningMapDetail',
    name: 'LearningMapDetail',
    component: () => import('@/views/php/LearningMapDetail.vue')
  },
  {
    // 強制登出
    path: '/Logout',
    name: 'Logout',
    component: () => import('@/views/php/Logout.vue')
  },
  {
    // 產品詳細資訊 (包套產品專用)
    path: '/ProductDetailByProductGroupList',
    name: 'ProductDetailByProductGroupList',
    component: () => import('@/views/php/ProductDetailByProductGroupList.vue')
  },
  {
    // 師生解題
    path: '/QuestionListsTeacher',
    name: 'QuestionListsTeacher',
    component: () => import('@/views/php/QuestionListsTeacher.vue')
  },
  {
    // 團購
    path: '/ShopGroupBuying',
    name: 'ShopGroupBuying',
    component: () => import('@/views/php/ShopGroupBuying.vue')
  },
  {
    // 平台
    path: '/Platform',
    name: 'Platform',
    component: () => import('@/views/php/Platform.vue')
  },
];

import { modulesList } from '@/include/config';
import Curriculum from '@/router/php/Curriculum';
import Subscription from '@/router/php/Subscription';

if(modulesList != null){
  let temp = null;

  if(modulesList.productGroupList != null && modulesList.productGroupList == true){
    temp = {
      path: '/ShoppingCart',
      name: 'ShoppingCart',
      component: () => import('@/views/php/ShoppingCartByProductGroupList.vue')
    };
    SbGen5.push(temp);
    temp = {
      path: '/OrderRecord',
      name: 'OrderRecord',
      component: () => import('@/views/php/OrderRecordByProductGroupList.vue')
    };
    SbGen5.push(temp);
  }
  if(modulesList.curriculumPlatform != null && modulesList.curriculumPlatform == true){
    SbGen5.push(...Curriculum);
  }
  if(modulesList.subscriptionPlatform != null && modulesList.subscriptionPlatform == true){
    SbGen5.push(...Subscription);
  }
  if(modulesList.voucheDiscount != null && modulesList.voucheDiscount == true){
    temp = {
      path: '/VoucherPaySelectProductSetting',
      name: 'VoucherPaySelectProductSetting',
      component: () => import('@/views/php/VoucherPaySelectProductSettingDiscount.vue')
    };
    SbGen5.push(temp);
  }
}
/*
  let sqls = SbGen5.map(obj => {
    let insertView = `INSERT IGNORE INTO \`sb5_vue\`.\`views\` (\`vid\`, \`code\`, \`name\`, \`description\`, \`seq\`, \`switch\`, \`created_at\`, \`updated_at\`) VALUES (NULL, '${obj.name}', '${obj.name}', '${obj.name}', '10', b'1', CURRENT_TIMESTAMP, CURRENT_TIMESTAMP)`;
    let insertRoleViewRt = `INSERT IGNORE INTO \`sb5_vue\`.\`role_view_rt\` (\`rvrid\`, \`rid\`, \`vid\`, \`seq\`, \`switch\`, \`created_at\`, \`updated_at\`) VALUES (NULL, '1', LAST_INSERT_ID(), '10', b'1', CURRENT_TIMESTAMP, CURRENT_TIMESTAMP)`;
    let insertRoleViewRt2 = `INSERT IGNORE INTO \`sb5_vue\`.\`role_view_rt\` (\`rvrid\`, \`rid\`, \`vid\`, \`seq\`, \`switch\`, \`created_at\`, \`updated_at\`) VALUES (NULL, '2', LAST_INSERT_ID(), '10', b'1', CURRENT_TIMESTAMP, CURRENT_TIMESTAMP)`;

    return `${insertView}; ${insertRoleViewRt}`;
 
  //  return `${insertView}; ${insertRoleViewRt}; ${insertRoleViewRt2}; ${insertRoleViewRt3}; ${insertRoleViewRt4}; ${insertRoleViewRt5}; ${insertRoleViewRt6}; ${insertRoleViewRt7}; ${insertRoleViewRt8}`;
  });
  console.log(sqls.join('; '));
  */
export default SbGen5;
  